import httpClient from '@/services/http-client';

export async function getAvailableInventories() {
  const response = await httpClient.get('/api/adm/inventory/list');

  return response.data;
}

export async function getInventoryDetails(inventoryId) {
  const response = await httpClient.get(`/api/adm/inventory/details/${inventoryId}`);

  return response.data;
}

export async function getAssociatedUsers(inventoryId) {
  const response = await httpClient.get(`/api/adm/inventory/associated-users/${inventoryId}`);

  return response.data;
}

export async function updateInventoryDetails(inventoryId, model) {
  const response = await httpClient.put(`/api/adm/inventory/details/${inventoryId}`, model);

  return response.data;
}

export async function createInventory(model) {
  const response = await httpClient.post('/api/adm/inventory/item', model);

  return response.data;
}

export async function deleteInventory(inventoryId) {
  const response = await httpClient.delete(`/api/adm/inventory/item/${inventoryId}`);

  return response.data;
}
