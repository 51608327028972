









































































import Vue from 'vue';
import Component from 'vue-class-component';

import { toasts } from '@/utils/toasts';

import {
  createInventory,
} from '../inventory-management-api';

@Component
export default class extends Vue {
  label: string|null = null;
  description: string|null = null;
  storekeeper: string|null = null;
  receivingCommitteeMembers: string|null = null;
  isActive: boolean = true;

  show() {
    this.label = null;
    this.description = null;
    this.storekeeper = null;
    this.receivingCommitteeMembers = null;
    this.isActive = true;

    (<any>(this.$refs.modal)).show();
  }

  validateFormFields() {
    const errors: Array<string> = [];
    if (!this.label) {
      errors.push('Cod gestiune necompletat');
    }
    if (!this.description) {
      errors.push('Denumire necompletată');
    }
    if (!this.storekeeper) {
      errors.push('Gestionar necompletat');
    }
    return errors;
  }

  async onOk(evt) {
    evt.preventDefault();
    const errors = this.validateFormFields();

    if (errors.length) {
      toasts.error(errors[0]);
      return;
    }

    const newInventory = {
      label: this.label,
      description: this.description,
      value: 'FUR',
      storekeeper: this.storekeeper,
      receivingCommitteeMembers: this.receivingCommitteeMembers,
      isActive: this.isActive,
    };

    try {
      await createInventory(newInventory);
      toasts.success('Gestiunea a fost adăugată cu success!');
      this.$emit('ok');
      (<any>(this.$refs.modal)).hide();
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }
}
