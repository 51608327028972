






















































import Vue from 'vue';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';

import i18n from '@/i18n';

import AddInventoryModal from './components/AddInventoryModal.vue';

import {
  getAvailableInventories,
} from './inventory-management-api';

@Component({
  components: { AddInventoryModal },
})
export default class extends Vue {
  hoveredRowIndex = -1;

  items: Array<any> = [];

  tableBusy = false;

  reportLoading = false;

  allItemsCount = 0;

  get fields() {
    return [
      {
        key: 'label',
        label: 'Cod',
      },
      {
        key: 'description',
        label: 'Descriere',
      },
      {
        key: 'type',
        label: 'Tip',
      },
      {
        key: 'storekeeper',
        label: 'Gestionar',
      },
      {
        key: 'isActiveForDisplay',
        label: i18n.t('mrn.grid.stare'),
      },
      {
        key: 'actiuni',
        label: i18n.t('mrn.grid.actiuni'),
        thClass: 'col-action',
        tdClass: 'col-action-body',
      },
    ];
  }

  onAdd() {
    (<any>(this.$refs.modal)).show();
  }

  async onInventoryAdded() {
    await this.loadGridPage();
  }

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  async loadGridPage() {
    try {
      this.tableBusy = true;

      const listResult = camelcaseKeys(await getAvailableInventories());

      this.allItemsCount = listResult.length;
      this.items = listResult.map((x) => ({
        ...x,
        isActiveForDisplay: x.isActive ? 'Activă' : 'Inactivă',
      }));
    } finally {
      this.tableBusy = false;
    }
  }

  async initialize() {
    await this.loadGridPage();
  }

  created() {
    if (!this.$store.getters.canAdminister) {
      this.$router.replace({ name: 'Dashboard' });
      return;
    }
    this.initialize();
  }
}
